import React from 'react'
import SEO from '../components/SEO'
import { Heading, Container, Section } from 'react-bulma-components';

import KlarnaConfirmation from '../components/KlarnaConfirmation/KlarnaConfirmation'

const Confirmation = () => {
  return (
    <>
      <SEO title="Orderbekräftelse | Wallang" />
      <Container>
        <Section>
          <Heading className="has-text-centered">Tack för din order!</Heading>
          <KlarnaConfirmation />
        </Section>
      </Container>
    </>
  )
}

export default Confirmation;
