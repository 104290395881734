import React, { useEffect, useState, useContext } from 'react'
import { Loader } from 'react-bulma-components';
import axios from 'axios'
import CartContext from '../../context/CartContext'
const queryString = require('query-string')

export default ({ sid }) => {
  const [loading, setLoading] = useState(true)
  const { clearCart } = useContext(CartContext)

  useEffect(
    () => {
      const queryParams = queryString.parse(window.location.search)

      if (queryParams && queryParams.sid) {
        axios
          .post('/.netlify/functions/order-get', { orderId: queryParams.sid })
          .then(res => {
            if (res.status === 200) {
              const checkoutContainer = document.getElementById('checkout-container')
              checkoutContainer.innerHTML = res.data.html_snippet
              const scriptsTags = checkoutContainer.getElementsByTagName('script')
              for (let i = 0; i < scriptsTags.length; i++) {
                const parentNode = scriptsTags[i].parentNode
                const newScriptTag = document.createElement('script')
                newScriptTag.type = 'text/javascript'
                newScriptTag.text = scriptsTags[i].text
                parentNode.removeChild(scriptsTags[i])
                parentNode.appendChild(newScriptTag)

                setLoading(false)
                localStorage.removeItem('orderId')
                clearCart()
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        window.location.replace('/')
      }
    },
    [clearCart]
  )

  return (
    <div>
      {loading && (
        <Loader
          style={{
            width: 50,
            height: 50,
            border: '2px solid #2980b9',
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            margin: '0 auto'
          }}
        />
      )}

      <div id="checkout-container" />
    </div>
  )
}
